import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/es/modal';
import Icon from 'antd/es/icon';

class ListWithModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      // Be careful here, currentElem is null on first hand until open modal is executed!!
      // @todo should improve that
      currentElem: null,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  openModal(elem) {
    this.setState({ opened: true, currentElem: elem });
  }

  closeModal() {
    const { onCloseModal } = this.props;
    onCloseModal();
    this.setState({ opened: false });
  }

  render() {
    const { modalContent, className, datas, render, closeIcon } = this.props;
    const { opened, currentElem } = this.state;

    return (
      <div>
        <Modal
          visible={opened}
          footer={null}
          onCancel={this.closeModal}
          style={{ maxWidth: '768px' }}
          centered
          className={className}
          destroyOnClose
          closeIcon={closeIcon}
        >
          {typeof modalContent === 'function'
            ? modalContent(currentElem)
            : modalContent}
        </Modal>

        {datas.map((elem) =>
          render(
            elem,
            () => {
              this.openModal(elem);
            },
            this.closeModal
          )
        )}
      </div>
    );
  }
}

ListWithModal.defaultProps = {
  className: 'modal-investors',
  modalContent: <div />,
  onCloseModal: () => {},
  closeIcon: <Icon type="close" />,
};

ListWithModal.propTypes = {
  datas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modalContent: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.func.isRequired,
  ]),
  className: PropTypes.string,
  render: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func,
  closeIcon: PropTypes.element,
};

export default ListWithModal;
