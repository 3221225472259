/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Layout from 'antd/es/layout';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import QueueAnim from 'rc-queue-anim';
import PropTypes from 'prop-types';

import BlogEntry from './BlogEntry';
import ListWithModal from '../../hoc/ListWithModal';
import BlogEdit from './BlogEdit';
import Polls from './Polls';
import PulseLoader from '../PulseLoader/index';
import BlogView, { renderActionButtons } from './BlogView';
import HtmlHelper from '../../utils/html';

class Blog extends React.Component {
  componentDidMount() {
    const { _listBlogs, articles } = this.props;
    if (articles.length < 1) {
      _listBlogs();
    }
    // fetchUsers();
  }

  // eslint-disable-next-line class-methods-use-this
  rearrangeBlogsAndSetFeatured(articles) {
    let articlesArranged = articles.slice(0).reverse();
    // clear all previous "isFeatured" tag as it may be outdated
    articlesArranged = articlesArranged.map((art) => {
      // eslint-disable-next-line no-param-reassign
      art.isFeatured = false;

      return art;
    });

    // put the ones featured first
    articlesArranged.sort(({ featured: a }, { featured: b }) => {
      if ((a && b) || (!a && !b)) {
        return 0;
      }
      if (a) {
        return -1;
      }

      return 1;
    });

    if (articlesArranged.length > 0) {
      articlesArranged[0].isFeatured = true;
    }

    return articlesArranged;
  }

  render() {
    const { id } = this.props.match.params;
    const { articles, isLoading, _vote } = this.props;
    const ButtonClose = <Button icon="up" type="danger" />;

    return isLoading ? (
      <PulseLoader />
    ) : (
      <Layout>
        <QueueAnim type="right" delay={10} duration={1500}>
          <Row gutter={8} className="blog-page">
            <BlogView id={id} articles={articles} _vote={_vote} />
            <ListWithModal
              className="modal-blog"
              closeIcon={ButtonClose}
              onCloseModal={() => {
                HtmlHelper.setUrlToRootBlog();
                HtmlHelper.setPageTitle(`Fun Invest Portal`);
              }}
              datas={this.rearrangeBlogsAndSetFeatured(articles)}
              render={(article, openModal) => (
                <BlogEntry openModal={openModal} art={article} />
              )}
              modalContent={(article) => {
                if (!article) {
                  return <div />;
                }

                return (
                  <div className="container layout-width">
                    {article.feature_image && article.feature_image.url ? (
                      <div
                        className="blog-header"
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${article.feature_image.url})`,
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            zIndex: 2,
                            background: 'rgba(0,0,0,.5)',
                          }}
                        >
                          <h2 className="blog-title overlayed">
                            {article.Title}
                          </h2>
                        </div>
                      </div>
                    ) : (
                      <h2 className="blog-title">{article.Title}</h2>
                    )}
                    {renderActionButtons(article)}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: article.Text.replaceAll(
                          'backend/uploads',
                          '/backend/uploads'
                        ),
                      }}
                      style={{ fontSize: '1.2em' }}
                    />
                    <Divider />
                    <Polls
                      _article={article}
                      vote={(voterId, choiceId) =>
                        _vote(article.id, choiceId, voterId)
                      }
                    />
                  </div>
                );
              }}
            />
          </Row>
        </QueueAnim>
        <BlogEdit />
      </Layout>
    );
  }
}

Blog.defaultProps = {
  articles: [],
};

Blog.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      Title: PropTypes.string.isRequired,
      Text: PropTypes.string.isRequired,
      Author: PropTypes.shape({}).isRequired,
    })
  ),
  _listBlogs: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  _vote: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  fetchUsers: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.any,
  }).isRequired,
};

export default Blog;
