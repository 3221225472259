import { connect } from 'react-redux';
import Blog from '../../../components/Blog';
import { loadBlogs, countMyVote } from '../../../redux/modules/Blog';
import { loadUsers } from '../../../redux/modules/Deltagere';

const mapStateToProps = (state) => ({
  articles: state.Blog.articles,
  isLoading: state.Blog.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  _listBlogs: () => dispatch(loadBlogs()),
  _vote: (articleId, choiceId, voterId) =>
    dispatch(countMyVote(articleId, choiceId, voterId)),
  fetchUsers: () => dispatch(loadUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
