import update from 'immutability-helper';
import findKey from 'lodash.findkey';
import cloneDeep from 'lodash.clonedeep';

export default class BlogModel {
  constructor() {
    this.articles = [];

    this.modalOpen = false;
    this.isSaving = false;
    this.isLoading = false;
    this.blogId = null;
    this.currentArticleInEdit = null;
    this.viewModalOpen = false;
  }

  openModal(id) {
    let currentArticle = findKey(this.articles, (p) => p.id === id);
    currentArticle = currentArticle ? parseInt(currentArticle, 10) : null;
    currentArticle =
      currentArticle > -1 ? cloneDeep(this.articles[currentArticle]) : null;
    return update(this, {
      modalOpen: { $set: true },
      blogId: { $set: id },
      currentArticleInEdit: { $set: currentArticle },
    });
  }

  closeModal() {
    return update(this, {
      modalOpen: { $set: false },
      blogId: { $set: null },
      currentArticleInEdit: { $set: null },
    });
  }

  toggleSaving() {
    return update(this, {
      isSaving: { $set: !this.isSaving },
    });
  }

  toggleLoading() {
    return update(this, {
      isLoading: { $set: !this.isLoading },
    });
  }

  store(data) {
    return update(this, {
      articles: { $set: data },
    });
  }

  save(data) {
    let indexOfCurrentBlog = findKey(this.articles, (p) => data.id === p.id);
    if (!indexOfCurrentBlog) {
      // creation
      return update(this, {
        articles: { $push: [data] },
      });
    }
    // update
    indexOfCurrentBlog = parseInt(indexOfCurrentBlog, 10);
    return update(this, {
      articles: {
        $splice: [[indexOfCurrentBlog, 1, data]],
      },
      connectedProfile: { $set: data },
    });
  }

  setVote(data) {
    // remove current id in all votes
    const articleKey = parseInt(
      findKey(this.articles, (art) => art.id === data.articleId),
      10
    );
    const pollsClone = cloneDeep(this.articles[articleKey].polls);
    const resultsClone = pollsClone.results.map((res) => {
      const voters = res.voters.filter((v) => v !== data.currentId);
      res.voters = voters;
      return res;
    });

    // add current id to correct vote
    resultsClone[data.choiceId].voters.push(data.currentId);

    return update(this, {
      articles: {
        [articleKey]: {
          polls: {
            results: {
              $set: resultsClone,
            },
          },
        },
      },
    });
  }

  toggleViewModal() {
    return update(this, {
      viewModalOpen: { $set: !this.viewModalOpen },
    });
  }
}
