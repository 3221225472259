import find from 'lodash.find';

import BlogModel from '../models/Blog';
import {
  createBlog,
  listBlogs,
  updateBlog,
  uploadFile,
} from '../../api/Methods';
import {
  notifyFailure,
  notifySuccess,
  notifyInfo,
} from '../../utils/notifications';
import errors from '../../utils/errors';

const BLOG_OPEN_MODAL = 'Blog/OpenModal';
const BLOG_CLOSE_MODAL = 'Blog/CloseModal';

const BLOG_REQUEST_SAVE = 'Blog/RequestSave';
const BLOG_SAVE_SUCCESS = 'Blog/RequestSuccess';
const BLOG_SAVE_FAILURE = 'Blog/RequestFailure';

const BLOG_REQUEST_LIST = 'Blog/RequestListSave';
const BLOG_LIST_SUCCESS = 'Blog/ListSuccess';
const BLOG_LIST_FAILURE = 'Blog/ListFailure';

const BLOG_VOTING = 'Blog/Voting';

const BLOG_TOGGLE_VIEW_MODAL = 'Blog/Toggle_view_modal';

const defaultState = new BlogModel();
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case BLOG_REQUEST_SAVE:
      return state.toggleSaving();
    case BLOG_SAVE_FAILURE:
      return state.toggleSaving();
    case BLOG_SAVE_SUCCESS:
      return state.save(action.payload).toggleSaving();

    case BLOG_OPEN_MODAL:
      return state.openModal(action.payload);
    case BLOG_CLOSE_MODAL:
      return state.closeModal();

    case BLOG_REQUEST_LIST:
      return state.toggleLoading();
    case BLOG_LIST_SUCCESS:
      return state.store(action.payload).toggleLoading();

    case BLOG_VOTING:
      return state.setVote(action.payload);

    case BLOG_TOGGLE_VIEW_MODAL:
      return state.toggleViewModal();

    default:
      return state;
  }
};

export const openModal = (id) => ({ type: BLOG_OPEN_MODAL, payload: id });
export const closeModal = () => ({ type: BLOG_CLOSE_MODAL });

export const countMyVote = (articleId, choiceId, voterId) => (
  dispatch,
  getState
) => {
  notifyInfo('Saving your vote...');

  dispatch({
    type: BLOG_VOTING,
    payload: {
      articleId,
      choiceId,
      currentId: voterId,
    },
  });

  const { Blog } = getState();
  const dataToSend = find(Blog.articles, (art) => art.id === articleId);

  if (!dataToSend) {
    return notifyFailure(
      'Voting failed',
      'An error has occured so your vote might have not been saved. Please refresh the page'
    );
  }

  return updateBlog(dataToSend)
    .then(() => notifySuccess('Your vote has been saved'))
    .catch((e) => notifyFailure('Voting failed', e.message));
};

export const saveBlog = (id, datas, noDispatchRequestSave = false) => (
  dispatch
) => {
  if (!noDispatchRequestSave) {
    dispatch({ type: BLOG_REQUEST_SAVE });
  }

  const method = !id ? createBlog : updateBlog;
  const datasToSend = Object.assign({}, datas);
  if (id) {
    datasToSend.id = id;
  }

  return method(datasToSend)
    .then((results) => {
      dispatch({ type: BLOG_SAVE_SUCCESS, payload: results.data });

      notifySuccess('Blog has been saved');
    })
    .catch((err) => {
      dispatch({ type: BLOG_SAVE_FAILURE });
      notifyFailure('Error', err.message);
    });
};

export const saveBlogWithFile = (id, datas, file) => (dispatch) => {
  dispatch({ type: BLOG_REQUEST_SAVE });

  // upload the file
  // ///////////////////
  const formData = new FormData();
  formData.append('files', file);

  return uploadFile(formData)
    .then((res) => {
      const _values = { ...datas };
      // add the file
      _values.feature_image = res.data;
      // now save the file
      return dispatch(saveBlog(id, _values, true));
    })
    .catch((err) => {
      dispatch({ type: BLOG_SAVE_FAILURE });
      notifyFailure('Error', err.message);
    });
  // ///////////////////
};

export const loadBlogs = () => (dispatch) => {
  dispatch({ type: BLOG_REQUEST_LIST });

  return listBlogs()
    .then((response) => {
      dispatch({ type: BLOG_LIST_SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: BLOG_LIST_FAILURE });
      errors.handle(err, dispatch);
    });
};

export const toggleViewModalAction = () => ({
  type: BLOG_TOGGLE_VIEW_MODAL,
});

export default reducer;
