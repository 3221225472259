import React, { useEffect, useState } from 'react';
import Layout from 'antd/es/layout';
import Row from 'antd/es/row';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
// import Select from 'antd/es/select';
import Progress from 'antd/es/progress';

import DeltagereEntry from './DeltagereEntry';
import DeltagereModal from './Modal';
import ListWithModal from '../../hoc/ListWithModal';
import PulseLoader from '../PulseLoader';

// const { Option } = Select;

const DeltagereChunk = ({ chunk, isEditMode, redxToggleModalEditMode }) => (
  <Row className="deltagere-row" gutter={10} style={{ marginLeft: '-10px' }}>
    <ListWithModal
      datas={chunk}
      className={isEditMode ? 'modal-blog' : 'modal-investors'}
      modalContent={<DeltagereModal />}
      render={(profile, openModal) => (
        <DeltagereEntry {...profile.props} toggleModal={openModal} />
      )}
      onCloseModal={() => {
        if (isEditMode) {
          redxToggleModalEditMode();
        }
      }}
    />
  </Row>
);

const Deltagere = ({
  profiles: _asProfiles,
  loading,
  isEditMode,
  redxToggleModalEditMode,
  // sectorOpts,
  fetchUsers,
}) => {
  const [filters, /* setFilters */] = useState([]);
  
  useEffect(() => {
    if (_asProfiles.length === 0) {
      fetchUsers();
    }
  }, []);

  const chunkArray = (myArray, size) => {
    const results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, size));
    }

    return results;
  };

  if (loading) return <PulseLoader />;

  // if (!profiles) return null;

  const profiles = (_asProfiles || []).filter((p) => p.email !== 'aina@wau.co');

  const filtered = filters.length > 0 ? profiles.filter(p => (p.Secteur.filter(x => filters.includes(x)).length > 0)) : profiles;

  const allProfiles = filtered.map((profile) => (
    <DeltagereEntry profile={profile} key={profile.id} />
  ));

  const chunks = chunkArray(allProfiles, 4);

  /* const handleFiltering = (v) => {
    setFilters(v);
  }; */

  return (
    <Layout>
        <div style={{ margin: '2% 0', padding: '3% 0' }}>
          {/* <Select
            className="sektor-select"
            mode="tags"
            showSearch
            placeholder="Filter"
            style={{ width: '100%' }}
            onChange={handleFiltering}
          >
            {sectorOpts.map((m) => (
              <Option key={`$-sek-${m}`} value={m}>
                {m}
              </Option>
            ))}
            </Select> */}
          <Progress
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
            format={(percent) => <h3>{percent}</h3>}
            percent={((profiles.length * 100) / 100)}
          />
        </div>
      <QueueAnim delay={10} duration={1500}>
        {chunks.length > 0 ? chunks.map((chunk, i) => (
          <DeltagereChunk
            // eslint-disable-next-line react/no-array-index-key
            key={`$.row.deltagere.${i}`}
            chunk={chunk}
            isEditMode={isEditMode}
            redxToggleModalEditMode={redxToggleModalEditMode}
          />
        )) : (
          <div 
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >Any Users Matched</div>
        )}
      </QueueAnim>
    </Layout>
  );
};

Deltagere.defaultProps = {
  profiles: [],
};

Deltagere.propTypes = {
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      Photo: PropTypes.shape({}),
      username: PropTypes.string.isRequired,
      Description: PropTypes.string,
      Phone: PropTypes.string,
    }).isRequired
  ),
  fetchUsers: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  redxToggleModalEditMode: PropTypes.func.isRequired,
};

export default Deltagere;
