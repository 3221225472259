/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import sum from 'lodash.sum';
import { Pie } from 'react-chartjs-2';
// eslint-disable-next-line no-unused-vars
import { Row, Col } from 'antd';
import ChartLegend from '../ChartLegend';

const PieChart = ({
  series,
  labels,
  backgroundColor,
  hoverBackgroundColor,
  sortFunc,
  openModal,
  investments,
  investmentsTotal,
  title,
}) => {
  const chartRef = React.createRef();
  const toggleModal = (record) => {
    if (record && typeof openModal === 'function') {
      openModal(record);
    }
  };
  const pieDatas = {
    labels,
    datasets: [
      {
        data: series,
        backgroundColor,
        hoverBackgroundColor,
      },
    ],
  };

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={12} lg={15}>
        <div className="pie-container">
        <Pie
          data={pieDatas}
          options={{
            maintainAspectRatio: true,
            aspectRatio: 2,
            plugins: {
              // Change options for ALL labels of THIS CHART
              datalabels: {
                color: '#fff',
                font: {
                  weight: 'bold',
                },
                formatter: (value, context) => {
                  const allDatasSum = sum(context.dataset.data);
                  return `${Math.round((100 * value) / allDatasSum, 0)} %`;
                },
              },
            },
            legend: {
              display: false,
            },
            // eslint-disable-next-line no-unused-vars
            legendCallback(chart) {
              return null;
            },
          }}
          ref={chartRef}
          width={400}
          height={400}
          onElementsClick={(elems) => {
            if (!elems || !elems[0]) return;

            const { _index } = elems[0];
            if (Number.isInteger(_index) && typeof openModal === 'function') {
              openModal(investments[_index]);
            }
          }}
        />
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={9} className="legend-container">
        {title}
        <ChartLegend
          data={[...investments, { Name: "Sum", color: "ffffff", displayAmount: investmentsTotal }]}
          columns={[
            {
              isLegend: true,
              title: 'Investering',
              dataIndex: 'Name',
              key: 'key',
            },
            {
              title: 'Vekt',
              dataIndex: 'displayAmount',
              key: 'andel',
              align: 'right',
              width: 60,
              render: (text, record) => {
                const percentage =
                  (record.displayAmount / investmentsTotal) * 100;
                return (
                  <div style={{ textAlign: 'right' }}>
                    <strong>{Math.round(percentage)} %</strong>
                  </div>
                );
              },
            },
            {
              title: 'Beløp',
              dataIndex: 'displayAmount',
              key: 'amount',
              sorter: true,
              width: 100,
              align: 'right',
              defaultSortOrder: 'descend',
            },
          ]}
          sortFunc={sortFunc}
          onRowClick={toggleModal}
        />
      </Col>
    </Row>
  );
};

PieChart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  backgroundColor: PropTypes.arrayOf(PropTypes.string).isRequired,
  hoverBackgroundColor: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortFunc: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default PieChart;
