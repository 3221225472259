import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { find } from 'lodash';
import Modal from 'antd/es/modal';
import Button from 'antd/es/button';

import Divider from 'antd/es/divider';
import { toggleViewModalAction } from '../../redux/modules/Blog';
import Polls from './Polls';
import HtmlHelper from '../../utils/html';

import './blogView.css';

export const renderActionButtons = (article) => (
  // <div className="article-actions-container">
  <Button
    onClick={() => {
      const blogPostUrl = `${HtmlHelper.getBaseUrl()}/share/blog/post/${
        article.id
      }`;
      HtmlHelper.copyTextToClipboard(blogPostUrl);
    }}
    type="primary"
    icon="share-alt"
    shape="circle"
    alt="Copy article direct link"
    size="large"
    className="article-share-btn"
  />
  // </div>
);

const renderArticle = (article, _voteFunc) => (
  <div className="container layout-width">
    {article.feature_image && article.feature_image.url ? (
      <div
        className="blog-header"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${article.feature_image.url})`,
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
            background: 'rgba(0,0,0,.5)',
          }}
        >
          <h2 className="blog-title overlayed">{article.Title}</h2>
        </div>
      </div>
    ) : (
      <h2 className="blog-title">{article.Title}</h2>
    )}
    {renderActionButtons(article)}
    <div
      dangerouslySetInnerHTML={{
        __html: article.Text.replaceAll('backend/uploads', '/backend/uploads'),
      }}
      style={{ fontSize: '1.2em' }}
    />
    <Divider />
    <Polls
      _article={article}
      vote={(voterId, choiceId) => _voteFunc(article.id, choiceId, voterId)}
    />
  </div>
);

const renderModal = (props, article) => {
  const { visible, toggleModal, _vote, urlPath } = props;
  const closeAction = () => {
    if(urlPath) {
      HtmlHelper.setUrlToGivinPath(urlPath);
    } else {
      HtmlHelper.setUrlToRootBlog();
    }
    HtmlHelper.setPageTitle('Fun Invest Portal');
    toggleModal();
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={closeAction}
      style={{ maxWidth: '768px' }}
      centered
      className="modal-blog"
      destroyOnClose
      closeIcon={<Button icon="up" type="danger" />}
    >
      {renderArticle(article, _vote)}
    </Modal>
  );
};

const BlogView = (props) => {
  const { id, articles,visible, toggleModal } = props;
  const matchingArticle = (article) => article.id === id && article.published;
  const theArticle = find(articles, matchingArticle);

  useEffect(() => {
    const articleIsValid = id && theArticle;
    if (articleIsValid) {
      HtmlHelper.setPageTitle(`${theArticle.Title}`);
      if(!visible) {
        toggleModal();
      }
    }
  }, [id, articles]);

  return (id && theArticle && renderModal(props, theArticle)) || null;
};

const mapStateToProps = ({ Blog }) => ({
  visible: Blog.viewModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      toggleModal: toggleViewModalAction,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogView);
